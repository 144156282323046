import { NextPage, NextPageContext } from "next";
import { ComponentType } from "react";

import ROUTES from "~/constants/routes";
import AuthService from "~/services/AuthService";

import { buildHOCDisplayNameForDev, warnIfWrappedApp } from "./utils";

interface WithAuthorizationProps {
  redirectForAuthorized?: string | null;
  redirectForUnauthorized?: string | null;
}

// prettier-ignore
const withAuthorization =
  // eslint-disable-next-line @typescript-eslint/ban-types
    <P extends object>({
      redirectForUnauthorized = ROUTES.auth.login,
      redirectForAuthorized
    }: WithAuthorizationProps = {}) =>
    (WrappedComponent: NextPage): NextPage => {
      const Wrapper: NextPage = (props: P): JSX.Element => {
        Wrapper.displayName = buildHOCDisplayNameForDev([
          withAuthorization as unknown as ComponentType,
          WrappedComponent
        ]);

        warnIfWrappedApp(WrappedComponent);

        return <WrappedComponent {...props} />;
      };
      if (WrappedComponent.getLayout) {
        Wrapper.getLayout = WrappedComponent.getLayout;
      }
      Wrapper.getInitialProps = async (ctx: NextPageContext): Promise<P> => {
        let componentProps = {} as P;

        if (WrappedComponent.getInitialProps) {
          componentProps = await WrappedComponent.getInitialProps(ctx);
        }
        const isAuthorize = await AuthService.authorizePage({
          context: ctx,
          redirectForAuthorized,
          redirectForUnauthorized
        });

        return { ...componentProps, isAuthorize };
      };

      return Wrapper;
    };

export default withAuthorization;
