import { NextLayoutComponentType } from "next";
import { NextPage, NextPageContext } from "next/types";
import nookies from "nookies";
import { ComponentType, FC } from "react";

import { THEME_MODE_COOKIE } from "~/constants/common";
import {
  DTOUserThemeSettingsQuery,
  DTOUserThemeSettingsQueryVariables,
  UserThemeSettingsQueryDocument
} from "~/graphql/userThemeSettingsQuery.generated";
import captureException from "~/utils/logging/captureException";

import { buildHOCDisplayNameForDev } from "./utils";

export default function withThemeMode(
  PageComponent: NextPage
): FC<Record<string, unknown>> {
  const WithThemeMode = (props: Record<string, unknown>): JSX.Element => (
    <PageComponent {...props} />
  );

  WithThemeMode.displayName = buildHOCDisplayNameForDev([
    WithThemeMode as unknown as ComponentType,
    PageComponent
  ]);

  if ((PageComponent as NextLayoutComponentType).getLayout) {
    WithThemeMode.getLayout = (
      PageComponent as NextLayoutComponentType
    ).getLayout;
  }

  WithThemeMode.getInitialProps = async (ctx: NextPageContext) => {
    const { urqlClient } = ctx;
    let pageProps = {};
    const themeModeCookie = nookies.get(ctx)[THEME_MODE_COOKIE];

    if (PageComponent.getInitialProps) {
      pageProps = await PageComponent.getInitialProps(ctx);
    }

    if (!urqlClient) {
      captureException("Urql client must be set to fetch Me query");

      return {
        ...pageProps
      };
    }

    if (process.env.NEXT_PUBLIC_ENABLE_SETTINGS_PERSONALIZATION !== "true") {
      return {
        ...pageProps
      };
    }

    const themeSettingsResponse = await urqlClient
      ?.query<DTOUserThemeSettingsQuery, DTOUserThemeSettingsQueryVariables>(
        UserThemeSettingsQueryDocument,
        {}
      )
      .toPromise();

    const themeSettings = themeSettingsResponse?.data;

    if (!themeSettings) {
      return {
        ...pageProps,
        themeModeCookie
      };
    }

    const themeOption = themeSettings.settings.theme;

    return {
      ...pageProps,
      themeOption,
      themeModeCookie
    };
  };

  return WithThemeMode;
}
