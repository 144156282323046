import Router, { useRouter } from "next/router";
import { useClient } from "urql";

import ROUTES from "~/constants/routes";
import { isAbsoluteUrl } from "~/utils/brandedTypes/absoluteUrl";
import { isNotNullable } from "~/utils/common";
import {
  DTOUserRewardCountQuery,
  DTOUserRewardCountQueryVariables,
  UserRewardCountDocument
} from "~/utils/useRestoreEntryPath/graphql/userRewardCount.generated";

const useRestoreEntryPath = (): (() => Promise<void>) => {
  const router = useRouter();
  const urqlClient = useClient();

  const hasUserAtLeastOneReward = async (): Promise<boolean> => {
    const response = await urqlClient
      .query<DTOUserRewardCountQuery, DTOUserRewardCountQueryVariables>(
        UserRewardCountDocument,
        {}
      )
      .toPromise();

    if (response.error) {
      return false;
    }

    const hasReward = response.data?.myNfts?.totalCount
      ? response.data?.myNfts?.totalCount > 0
      : false;

    return hasReward;
  };

  const getRedirectUrl = async (): Promise<string> => {
    const nextURL = Array.isArray(router.query.next)
      ? router.query.next[0]
      : router.query.next;

    const backRedirectRequired =
      isNotNullable(nextURL) && !isAbsoluteUrl(nextURL);

    if (backRedirectRequired) {
      return nextURL;
    }

    const hasAtLeastOneReward = await hasUserAtLeastOneReward();

    return hasAtLeastOneReward ? ROUTES.items.index : ROUTES.index;
  };

  const redirect = async (): Promise<void> => {
    const redirectUrl = await getRedirectUrl();

    await Router.push(redirectUrl);
  };

  return redirect;
};

export default useRestoreEntryPath;
