
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { NextPage } from "next";
import { ReactNode } from "react";
import Login from "~/components/auth/Login";
import Meta from "~/components/common/Meta/Meta";
import withAuthorization from "~/components/HOCs/withAuthorization";
import withThemeMode from "~/components/HOCs/withThemeMode";
import AuthLayout from "~/components/layouts/AuthLayout";
import withUrqlSSR from "~/components/providers/UrqlProvider/withUrqlSSR";
import ROUTES from "~/constants/routes";
import compose from "~/utils/compose";
const LoginPage: NextPage = (): JSX.Element => (<>
    <Meta pageKey="login"/>
    <Login />
  </>);
LoginPage.getLayout = function GetAuthLayout(page: ReactNode): JSX.Element {
    return <AuthLayout>{page}</AuthLayout>;
};
const __Next_Translate__Page__18c1550c007__ = compose(withAuthorization({
    redirectForUnauthorized: null,
    redirectForAuthorized: ROUTES.index
}), withUrqlSSR, withThemeMode)(LoginPage);

    export default __appWithI18n(__Next_Translate__Page__18c1550c007__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  