/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOUserThemeSettingsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DTOUserThemeSettingsQuery = {
  __typename?: "Query";
  settings: { __typename?: "PersonSettings"; theme: Types.DTOTheme };
};

export const UserThemeSettingsQueryDocument = gql`
  query UserThemeSettingsQuery {
    settings {
      theme
    }
  }
`;

export function useUserThemeSettingsQuery(
  options?: Omit<Urql.UseQueryArgs<DTOUserThemeSettingsQueryVariables>, "query">
) {
  return Urql.useQuery<
    DTOUserThemeSettingsQuery,
    DTOUserThemeSettingsQueryVariables
  >({ query: UserThemeSettingsQueryDocument, ...options });
}
