import { useRouter } from "next/router";
import { useCallback } from "react";
import { useClient } from "urql";

import { DTOPlatform, DTOSocialType } from "~/declarations/graphql/types";
import useHandleGraphQLErrors from "~/utils/graphql/errors/useHandleGraphQLErrors";

import {
  DTOGetSocialLoginUrlQuery,
  DTOGetSocialLoginUrlQueryVariables,
  GetSocialLoginUrlQueryDocument
} from "./graphql/getSocialLoginUrl.generated";

type GetSocialLoginHandler = () => Promise<void>;

export default function useSocialLogin(state: string): GetSocialLoginHandler {
  const uralClient = useClient();
  const router = useRouter();
  const handleGraphQLErrors = useHandleGraphQLErrors();

  return useCallback(async () => {
    const { data, error } = await uralClient
      .query<DTOGetSocialLoginUrlQuery, DTOGetSocialLoginUrlQueryVariables>(
        GetSocialLoginUrlQueryDocument,
        {
          platform: DTOPlatform.Web,
          type: DTOSocialType.Linkedin,
          state
        }
      )
      .toPromise();

    if (error) {
      handleGraphQLErrors(error);
      return;
    }

    const socialLoginUrl = data?.getSocialLoginUrl;

    if (socialLoginUrl) {
      router.push(socialLoginUrl);
    }
  }, [handleGraphQLErrors, router, state, uralClient]);
}
