import { NextPage } from "next";
import { ComponentType } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProcessedComponent = NextPage | ComponentType<any>;

export function buildHOCDisplayNameForDev(
  components: ProcessedComponent[]
): string | undefined {
  if (process.env.NODE_ENV === "production") {
    return;
  }

  return components.reduce((combinedName, NextComponent) => {
    const displayName =
      NextComponent.displayName || NextComponent.name || "Component";

    return `${combinedName}${displayName}`;
  }, "");
}

export function warnIfWrappedApp(pageComponent: ProcessedComponent): void {
  const pageComponentName =
    pageComponent.displayName || pageComponent.name || "Component";

  if (pageComponentName === "App") {
    console.warn("The withLayout HOC only works with page components.");
  }
}
