import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";

import Icon from "~/components/core/Icon";

type Props = {
  label: string;
  onClick: () => void;
};

const AuthLinkedinActionWithDivider = ({
  label,
  onClick
}: Props): JSX.Element => {
  const { t } = useTranslation("auth");

  return (
    <Wrapper>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={onClick}
        startIcon={<Icon variant="linkedinSquareColorful" size={20} />}
        fullWidth
      >
        {label}
      </Button>
      <DividerWrapper>
        <StyledDivider />
        <OrText>{t("or")}</OrText>
      </DividerWrapper>
    </Wrapper>
  );
};

export default AuthLinkedinActionWithDivider;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
`;

const DividerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const OrText = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.background.octonary};
  padding: ${({ theme }) => theme.spacing(0, 2.5)};
  z-index: 1;
`;
OrText.defaultProps = {
  variant: "titleQuaternary"
};

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;
`;
