/* eslint-disable @typescript-eslint/no-explicit-any  */

type RC<P> = React.FC<P> | React.ComponentClass<P>;

interface ComponentEnhancer<TInner, TOuter> {
  (component: RC<TInner>): RC<TOuter>;
}

type HOC<TInner, TOuter> = (C: RC<TInner>) => RC<TOuter>;

const compose = <TInner, TOuter>(
  hoc: HOC<any, any>,
  ...hocs: HOC<any, any>[]
): ComponentEnhancer<TInner, TOuter> =>
  hocs.reduce(
    (prevHOC, nextHOC) =>
      /* @ts-expect-error non-trivial type conversation */
      (value): HOC<any, any> =>
        /* @ts-expect-error non-trivial type conversation */
        prevHOC(nextHOC(value)),
    hoc
  );

export default compose;
