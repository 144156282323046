import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import useTranslation from "next-translate/useTranslation";

import EmailField from "~/components/common/EmailField";
import PasswordField from "~/components/common/PasswordField/PasswordField";
import ROUTES from "~/constants/routes";
import EmojiWavingHandImage from "~/public/assets/images/EmojiWavingHand.png";

import AuthActionButton from "../common/AuthActionButton";
import AuthContent from "../common/AuthContent/AuthContent";
import AuthLinkedinAction from "../common/AuthLinkedinActionWithDivider";
import useSocialLogin from "../useSocialLogin/useSocialLogin";
import useLoginForm from "./useLoginForm";

const FOOTER_LINK_PROPS = {
  textTranslationKey: "auth:not-member",
  link: {
    href: ROUTES.auth.signup,
    labelTranslationKey: "auth:sign-up"
  }
};

const Login = (): JSX.Element => {
  const { t } = useTranslation("auth");
  const {
    submitDisabled,
    hasErrors,
    values,
    handleSubmit,
    handleChange,
    handleClearEmail,
    handleClearPassword
  } = useLoginForm();
  const socialLogin = useSocialLogin(ROUTES.auth.login);

  return (
    <AuthContent
      captionEmojiImage={EmojiWavingHandImage.src}
      caption={t("login_caption")}
      subCaption={t("login_sub-caption")}
      footerProps={FOOTER_LINK_PROPS}
    >
      <AuthLinkedinAction
        label={t("login_via-linkedin")}
        onClick={socialLogin}
      />
      <Form onSubmit={handleSubmit}>
        <EmailField
          name="email"
          label={t("common:email")}
          placeholder={t("email-placeholder")}
          error={hasErrors}
          value={values.email}
          onChange={handleChange}
          onClickClear={handleClearEmail}
        />
        <PasswordField
          color="secondary"
          name="password"
          label={t("password")}
          placeholder={t("password_placeholder")}
          error={hasErrors}
          value={values.password}
          onClear={handleClearPassword}
          onChange={handleChange}
          fullWidth
        />
        <ForgotPasswordLinkButton>
          {t("login_forgot-password")}
        </ForgotPasswordLinkButton>
        <SubmitButton type="submit" disabled={submitDisabled}>
          {t("login")}
        </SubmitButton>
      </Form>
    </AuthContent>
  );
};

export default Login;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

const ForgotPasswordLinkButton = styled(Button)`
  margin-left: auto;
`;
ForgotPasswordLinkButton.defaultProps = {
  color: "brandPurple",
  size: "extraLarge",
  variant: "text",
  href: ROUTES.auth.resetPassword
};

const SubmitButton = styled(AuthActionButton)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;
