/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOGetSocialLoginUrlQueryVariables = Types.Exact<{
  type: Types.DTOSocialType;
  platform: Types.DTOPlatform;
  state?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type DTOGetSocialLoginUrlQuery = {
  __typename?: "Query";
  getSocialLoginUrl: string;
};

export const GetSocialLoginUrlQueryDocument = gql`
  query GetSocialLoginUrlQuery(
    $type: SocialType!
    $platform: Platform!
    $state: String
  ) {
    getSocialLoginUrl(type: $type, platform: $platform, state: $state)
  }
`;

export function useGetSocialLoginUrlQuery(
  options: Omit<Urql.UseQueryArgs<DTOGetSocialLoginUrlQueryVariables>, "query">
) {
  return Urql.useQuery<
    DTOGetSocialLoginUrlQuery,
    DTOGetSocialLoginUrlQueryVariables
  >({ query: GetSocialLoginUrlQueryDocument, ...options });
}
