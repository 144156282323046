import { useFormik, UseFormikResult } from "formik";
import useTranslation from "next-translate/useTranslation";
import { object as yupObject, string as yupString } from "yup";

import { useAuthenticationContext } from "~/components/providers/AuthenticationContextProvider/AuthenticationContext";
import useSnackbar from "~/components/providers/SnackbarProvider/useSnackbar";
import useRestoreEntryPath from "~/utils/useRestoreEntryPath";

import { useLoginMutation } from "./graphql/loginMutation.generated";

type LoginForm = {
  email: string;
  password: string;
};

const INITIAL_VALUES = {
  email: "",
  password: ""
};

const VALIDATION_SCHEMA = yupObject({
  email: yupString().required(),
  password: yupString().required()
});

type UseFormikResultLoginForm = UseFormikResult<LoginForm>;

type UseLoginFormResult = {
  values: UseFormikResultLoginForm["values"];
  hasErrors: boolean;
  submitDisabled: boolean;
  handleChange: UseFormikResultLoginForm["handleChange"];
  handleSubmit: UseFormikResultLoginForm["handleSubmit"];
  handleClearEmail: () => void;
  handleClearPassword: () => void;
};

export default function useLoginForm(): UseLoginFormResult {
  const { t } = useTranslation("auth");
  const [, loginMutation] = useLoginMutation();
  const { setAuthenticated } = useAuthenticationContext();
  const redirectAfterAuthorization = useRestoreEntryPath();
  const { enqueueSnackbar } = useSnackbar();

  const {
    errors,
    values,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    setErrors,
    touched
  } = useFormik<LoginForm>({
    initialValues: INITIAL_VALUES,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: async values => {
      try {
        const result = await loginMutation({
          request: {
            identity: values.email,
            credential: values.password
          }
        });

        if (result.error) {
          enqueueSnackbar(t("wrong-email-or-password"), {
            variant: "error"
          });
          setErrors({ email: "error", password: "error" });

          return;
        }

        const accessToken = result.data?.login?.accessToken.value;

        if (!accessToken) {
          enqueueSnackbar(t("common:something-went-wrong"), {
            variant: "error"
          });

          return;
        }

        setAuthenticated(accessToken);

        await redirectAfterAuthorization();
      } catch (err) {
        enqueueSnackbar(String(err), {
          variant: "error"
        });
      }
    }
  });

  const handleClearEmail = (): void => {
    setFieldValue("email", "");
  };

  const handleClearPassword = (): void => {
    setFieldValue("password", "");
  };

  const emailError = Boolean(errors.email) && Boolean(touched.email);
  const passwordError = Boolean(errors.password) && Boolean(touched.password);
  const hasErrors = emailError || passwordError;

  return {
    submitDisabled: isSubmitting || hasErrors,
    hasErrors,
    values,
    handleSubmit,
    handleChange,
    handleClearEmail,
    handleClearPassword
  };
}
