/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOUserRewardCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type DTOUserRewardCountQuery = {
  __typename?: "Query";
  myNfts: { __typename?: "BoughtNftStatsList"; totalCount: number };
};

export const UserRewardCountDocument = gql`
  query UserRewardCount {
    myNfts {
      totalCount
    }
  }
`;

export function useUserRewardCountQuery(
  options?: Omit<Urql.UseQueryArgs<DTOUserRewardCountQueryVariables>, "query">
) {
  return Urql.useQuery<
    DTOUserRewardCountQuery,
    DTOUserRewardCountQueryVariables
  >({ query: UserRewardCountDocument, ...options });
}
