/* eslint-disable cleo/no-deep-imports */
import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../../declarations/graphql/types";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOLoginMutationVariables = Types.Exact<{
  request: Types.DTOLoginRequest;
}>;

export type DTOLoginMutation = {
  __typename?: "Mutation";
  login: {
    __typename?: "TokenResponse";
    accessToken: {
      __typename?: "AccessToken";
      value: string;
      expiresAt: string;
    };
  };
};

export const LoginMutationDocument = gql`
  mutation LoginMutation($request: LoginRequest!) {
    login(request: $request) {
      accessToken {
        value
        expiresAt
      }
    }
  }
`;

export function useLoginMutation() {
  return Urql.useMutation<DTOLoginMutation, DTOLoginMutationVariables>(
    LoginMutationDocument
  );
}
