import { NextLayoutComponentType } from "next";
import { NextPage, NextPageContext } from "next/types";
import { ComponentType, FC } from "react";
import ssrPrepass from "react-ssr-prepass";

import { buildHOCDisplayNameForDev } from "~/components/HOCs/utils";
import { isServerSide } from "~/utils/common";

import initUrqlClient from "./initUrqlClient";

export default function withUrqlSSR(
  PageComponent: NextPage,
  suspense = false
): FC<Record<string, unknown>> {
  const WithUrqlSSR = (props: Record<string, unknown>): JSX.Element => (
    <PageComponent {...props} />
  );

  WithUrqlSSR.displayName = buildHOCDisplayNameForDev([
    withUrqlSSR as unknown as ComponentType,
    PageComponent
  ]);

  if ((PageComponent as NextLayoutComponentType).getLayout) {
    WithUrqlSSR.getLayout = (
      PageComponent as NextLayoutComponentType
    ).getLayout;
  }

  if (!PageComponent.getInitialProps) {
    return WithUrqlSSR;
  }

  WithUrqlSSR.getInitialProps = async (ctx: NextPageContext) => {
    const { AppTree } = ctx;
    // Run the wrapped component's getInitialProps function
    let pageProps = {};

    const { urqlClient, ssrCache } = initUrqlClient({ ctx });

    if (PageComponent.getInitialProps) {
      pageProps = await PageComponent.getInitialProps({ ...ctx, urqlClient });
    }

    // getInitialProps is universal, but we only want
    // to run server-side rendered suspense on the server
    if (!isServerSide()) {
      return pageProps;
    }

    if (suspense) {
      // Run suspense and hence all urql queries
      await ssrPrepass(
        <AppTree
          pageProps={{
            ...pageProps,
            urqlClient
          }}
        />
      );
    }

    // Extract query data from the urql store
    // Extract the SSR query data from urql's SSR cache
    const urqlState = ssrCache?.extractData();

    return {
      ...pageProps,
      urqlState
    };
  };

  return WithUrqlSSR;
}
